<template>
    <div class="Footer">
      <div class="Top">
        <div class="Left">          
          <router-link to="/" class="Logo"><img src="@/images/logo_w.png" alt="Logo Conseil Solution Professionnel Blanc"/></router-link>
          <a class="Tel" href="tel:+33630335260">
            <img src="@/images/call_w.svg" alt="">
            <div>06 30 33 52 60</div>
          </a>
        </div>
        <div class="Right">
          <div class="Link" v-for="(link_,i) in lstOnglets" :key="'Link#'+i"><router-link :to="link_.href">{{link_.text}}</router-link></div>
        </div>
      </div>
      <div class="Bottom">
        <div class="Left Copyright"><span>Copyright 2022 © Conseil Solution Professionnel</span><span>Réalisé par <a href="http://www.lavitrineweb.fr/" target="_blank">La Vitrine Web</a></span></div>
        <div class="Right Link"><router-link to="/mentions-legales">Mentions légales</router-link></div>
      </div>
    </div>  
</template>

<script>
export default {
  name: "Footer",
  props: {
  },
  data(){
    return{
      lstOnglets:[
        {
          text:"Santé",
          href:"/sante"
        },
        {
          text:"Prévoyance",
          href:"/prevoyance"
        },
        {
          text:"Audit Retraite",
          href:'/audit-retraite'
        },
        {
          text:"Audit Patrimonial",
          href:'/audit-patrimonial'
        }
      ]
    }
  }
};
</script>