import { createRouter, createWebHistory } from 'vue-router'
import Home from "../views/Home.vue";
import Sante from "../views/Sante.vue";
import Prevoyance from "../views/Prevoyance.vue";
import Patrimoniale from "../views/Patrimoniale.vue";
import Retraite from "../views/Retraite.vue";
import MentionsLegales from "../views/MentionsLegales.vue";
import Contact from "../views/Contact.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'CSP + - Conseil Solution Professionnel',
      metaTags: [
        {
          name: 'description',
          content: 'CSP+ Cabinet de conseil et de courtage indépendant dédié à la protection sociale et patrimoniale.'
        },
        {
          property: 'keywords',
          content: 'protection sociale, patrimoniale, conseil, TNS, indépendant, auto entrepreneur, anglet, pays basque, pyrénées-atlantiques, chef d’entreprise, commerçant'
        }
      ]
    }
  },
  {
    path: "/sante",
    name: "sante",
    component: Sante,
    meta: {
      title: 'Santé - Conseil Solutions Professionnel',
      metaTags: [
        {
          name: 'description',
          content: 'Faites votre étude comparative pour votre mutuelle individuelle ou collective avec un expert en complémentaire santé.'
        },
        {
          property: 'keywords',
          content: 'protection sociale, patrimoniale, conseil, TNS, indépendant, auto entrepreneur, anglet, pays basque, pyrénées-atlantiques, chef d’entreprise, commerçant, indépendant, individuel, collectif, convention collective, garanties, remboursements, frais de santé, mutuelle, complémentaire santé, dentaire, optique, médecines douces, hospitalisation'
        }
      ]
    }
  },
  {
    path: "/prevoyance",
    name: "prevoyance",
    component: Prevoyance,
    meta: {
      title: 'Prévoyance - Conseil Solutions Professionnel',
      metaTags: [
        {
          name: 'description',
          content: 'Partenaire de nombreux assureurs, le cabinet vous accompagne dans l\'analyse de vos besoins à travers d\'un audit personnalisé afin de vous proposer un contrat adapté.'
        },
        {
          property: 'keywords',
          content: 'protection sociale, patrimoniale, conseil, TNS, indépendant, auto entrepreneur, anglet, pays basque, pyrénées-atlantiques, chef d’entreprise, commerçant, prévoyance, arrêt de travail, indemnité, incapacité, invalidité, artisan, commerçant, chef d’entreprise, indépendant, auto-entrepreneur, profession libérales, décès'
        }
      ]
    }
  },
  {
    path: "/audit-retraite",
    name: "retraite",
    component: Retraite,
    meta: {
      title: 'Audit-retraite - Conseil Solutions Professionnel',
      metaTags: [
        {
          name: 'description',
          content: 'Par le biais de nombreux dispositifs fiscaux, nous vous proposerons le contrat adapté afin de préparer votre retraite.'
        },
        {
          property: 'keywords',
          content: 'protection sociale, patrimoniale, conseil, TNS, indépendant, auto entrepreneur, anglet, pays basque, pyrénées-atlantiques, chef d’entreprise, commerçant, retraite, loi pacte, per, pension, carrière, âge retraite'
        }
      ]
    }
  },
  {
    path: "/audit-patrimonial",
    name: "patrimoniale",
    component: Patrimoniale,
    meta: {
      title: 'Audit Patrimonial - Conseil Solutions Professionnel',
      metaTags: [
        {
          name: 'description',
          content: 'Le cabinet vous aide à organiser et structurer votre patrimoine (financiers et immobiliers) en fonction de vos objectifs et projets.'
        },
        {
          property: 'keywords',
          content: 'protection sociale, patrimoniale, conseil, TNS, indépendant, auto entrepreneur, anglet, pays basque, pyrénées-atlantiques, chef d’entreprise, commerçant, assurance vie, épargne, fiscalité, immobilier, placement, indépendance financière'
        }
      ]
    }
  },
  {
    path: "/mentions-legales",
    name: "mentions_legales",
    component: MentionsLegales,
    meta: {
      title: 'Mentions légales - Conseil Solutions Professionnel',
      metaTags: [
        {
          name: 'robots',
          content: 'noindex,follow'
        }
      ]
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      title: 'Contact - Conseil Solutions Professionnel',
      metaTags: [
        {
          name: 'description',
          content: 'Contactez-nous pour avoir plus d\'informations sur nos services.'
        },
        {
          property: 'keywords',
          content: 'protection sociale, patrimoniale, conseil, TNS, indépendant, auto entrepreneur, anglet, pays basque, pyrénées-atlantiques, chef d’entreprise, commerçant, contact, téléphone, mail, devis, adresse, adresse postale'
        }
      ]
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const metaTags = to.meta.metaTags;
  if (metaTags) {
    metaTags.forEach(tagDef => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
      document.head.appendChild(tag);
    });
  }
  next();
});

export default router
