<template>
  <div class="Page Contact">
      <Ctct/>
  </div>
</template>

<script>
import Ctct from "@/components/Contact.vue";
export default {
  name: "Contact",
  components: {
    Ctct
  },
  created () {
      this.$store.commit("currentPage","contact")
      this.$store.commit("toggleMenuMobile",false)
  }
};
</script>
