<template>
  <div class="Page Patrimoniale">
    <div class="BigTitle Small">
        <div class="Trait" >
          <span class="Barre"></span><span class="Bullet"></span>
        </div>
        <div class="Text">Audit Patrimonial</div>
    </div>
    <div class="Part Professionnels">
      <div class="Princ">
        <img class="Icon" src="@/images/briefcase.svg" alt="">
        <div class="Title">Professionnels</div>
        <div class="SubTitle">Assurer la pérénité de votre entreprise !</div>
        <div class="Text">Notre cabinet accompagne votre entreprise dans la mise en place d'une stratégie d'optimisation en matière de valorisation de vos actifs financiers.</div>
        <div class="AllButtons">
          <div class="Button"><span>Assurance vie</span><span>(ART.82)</span></div>
          <div class="Button"><span>Trésorerie</span><span>d'entreprise</span></div>
        </div>
      </div>
      <div class="Illustrations">
        <img src="@/images/patrimonialPro.jpg" alt="">
      </div>
    </div>
    <div class="Part Particuliers">
      <div class="Princ">
        <img class="Icon" src="@/images/hand-holding-heart.svg" alt="">
        <div class="Title">Particuliers</div>
        <div class="Text">CSP+ vous aide à organiser et structurer votre patrimoine (financiers et immobiliers) en fonction de vos objectifs et projets (études des enfants, optimiser sa fiscalité, alléger ses droits de succession, etc ...).</div>
        <div class="AllButtons">
          <div class="Button">Assurance vie</div>
          <div class="Button">Investissement immobilier</div>
        </div>
      </div>
      <div class="Illustrations">
        <img src="@/images/patrimonialPart.jpg" alt="">
      </div>
    </div>
    <div class="Liens" style="display:none">
      <router-link to="/contact"  class="Lien Contact">Contactez-nous</router-link>
      <router-link to="/actualite" class="Lien Actus">Découvrez nos actus</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "Patrimoniale",
  components: {
  },
  created () {
      this.$store.commit("currentPage","audit-patrimonial")
      this.$store.commit("toggleMenuMobile",false)
  }
};
</script>
