<template>
  <div class="Contact">
    <div class="BigTitle Small">
      <div class="Trait">
        <span class="Barre"></span><span class="Bullet"></span>
      </div>
      <div class="Text">Contactez-nous</div>
    </div>
    <div class="Contact_Form">
      <div class="Infos_Container">
        <div class="Logos">
          <img
            class="Logo"
            src="@/images/logo.svg"
            alt="Logo Conseil Solution Professionnel"
          />
        </div>
        <div class="Infos">
          <div class="Info">
            <img src="@/images/icons/phone.png" alt="" />
            <div class="value">06 30 33 52 60</div>
          </div>
          <div class="Info">
            <img src="@/images/icons/localisation.png" alt="" />
            <div class="value">9 All. Mouesca, 64600 Anglet</div>
          </div>
          <div class="Info">
            <img src="@/images/icons/mail.png" alt="" />
            <div class="value">gestion@csp-plus.com</div>
          </div>
        </div>
      </div>
      <div class="Form">
        <div class="Ligne">
          <div class="Input" :class="{Error: errornom === true}">
            <div class="Libelle">Nom *</div>
            <input type="text" v-model="nom" />
          </div>
          <div class="Input">
            <div class="Libelle">Enteprise</div>
            <input type="text" v-model="entreprise" />
          </div>
        </div>
        <div class="Ligne">
          <div class="Input" :class="{Error: errormail === true}">
            <div class="Libelle">Adresse Email *</div>
            <input type="text" v-model="mail" />
          </div>
          <div class="Input" :class="{Error: errorphone === true && phone.trim() !=''}">
            <div class="Libelle">N° de téléphone</div>
            <input type="text" v-model="phone" />
          </div>
        </div>
        <div class="Ligne">
          <div class="Input" :class="{Error: errormessage === true}">
            <div class="Libelle">Message *</div>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              v-model="message"
            ></textarea>
          </div>
        </div>
        <div class="Ligne MessageStatus" :class="{Error: status !=='Votre mail a bien été envoyé !'}" v-html="status" v-show="status != ''" ></div>
        <div class="Ligne Liens" v-show="status !=='Votre mail a bien été envoyé !'" >
          <div class="Lien Contact" @click="sendMail()">Envoyer</div>
        </div>
      </div>
    </div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d92581.71553664788!2d-1.500291!3d43.519468!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3e2c61e568cfae65!2sCONSEIL%20SOLUTION%20PROFESSIONNEL%20%2B!5e0!3m2!1sfr!2sfr!4v1643280886286!5m2!1sfr!2sfr"
      width="800"
      height="500"
      style="border: 0; width: 100%; filter: grayscale(1)"
      class="Map"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService.js";

export default {
  name: "Contact",
  props: {},
  data() {
    return {
      errornom: false,
      errormail: false,
      errormessage: false,
      errorphone: false,
      nom: "",
      entreprise: "",
      mail: "",
      phone: "",
      message: "",
      status:""
    };
  },
  methods: {
    async sendMail() {
      
      var valid = true;
      this.status="En cours ...";
      
      var mail_fromname= this.nom ;
      if(this.entreprise!=""){
        mail_fromname += " ("+this.entreprise+")";
      }

      if(this.nom.trim() == ""){
        valid=false;
        this.errornom=true;
      }

      if(this.mail.trim() == "" || (this.mail.trim() !== "" && this.validEmail() === false)){
        valid=false;
        this.errormail=true;
      }

      if(this.phone.trim() !== "" && this.validPhone() === false){
        valid=false;
        this.errorphone=true;
      }

      if(this.message.trim() == ""){
        valid=false;
        this.errormessage=true;
      }

      if(valid){
        const response = await ApiService.sendMail({
          mail_from: "routagemail@lavitrineweb.fr",
          mail_fromname: mail_fromname,
          mail_to:"gestion@csp-plus.com", 
          // mail_to:"lasarthe6413@gmail.com",
          mail_replyto:this.mail,
          mail_htmlpart:this.message,
          mail_textpart:this.message,
          mail_tel:this.phone
        });
        if (response.status == "ok") {
          this.nom= "";
          this.entreprise= "";
          this.mail= "";
          this.phone= "";
          this.message= "";
          this.errornom=false;
          this.errormessage=false;
          this.errormail=false;
          this.status="Votre mail a bien été envoyé !";
        }else{        
          this.status="Une erreur s'est produite lors de l'envoi de votre mail ! <br/>N'hésitez pas à nous joindre au 06 30 33 52 60.";
        }
      
        setTimeout(() => {  
          this.status="";
        }, 4000);
      }else{
        this.status = "Des champs obligatoires ne sont pas correctement remplis."
      }
    },
    validEmail() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.mail);
    },
    validPhone() {
      if(this.phone.length <= 10){
        var re = /^\d{10}$/;
        return re.test(this.phone);
      }else{
        var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return re.test(this.phone);
      }
    }
  },
};
</script>