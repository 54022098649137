<template>
  <div class="MentionsLegales">
    <h1>This is an about page</h1>
  </div>
</template>
<script>

export default {
  name: "MentionsLegales",
  components: {
  },
  created () {
      this.$store.commit("toggleMenuMobile",false)
  }
};
</script>
