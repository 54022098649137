import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)

// Utilisation du routeur
app.use(router)

// Utilisation du magasin Vuex
app.use(store)

// Définition des clés d'API comme mixin global
app.mixin({
  data() {
    return {
      MJ_APIKEY_PUBLIC: '5611d975af74f880dd9a45d6c758f845',
      MJ_APIKEY_PRIVATE: '346afef66c57a4914a7650f5be4b403c'
    }
  }
})

// Montage de l'application
app.mount('#app')
