<template>
  <div class="Page Prevoyance">
    <div class="BigTitle Small">
        <div class="Trait" >
          <span class="Barre"></span><span class="Bullet"></span>
        </div>
        <div class="Text">Prévoyance</div>
    </div>
    <div class="Part Professionnels">
      <div class="Princ">
        <img class="Icon" src="@/images/briefcase.svg" alt="">
        <div class="Title">Professionnels</div>
        <div class="Text">Dédié aux cheffes et chefs d’entreprises, artisans, commerçants, profession libérales, l’assurance prévoyance a pour but de protéger le souscripteur et sa famille contre les quatre principaux risques liés aux accidents de la vie : l’incapacité, l’invalidité, la dépendance et le décès.<br><br>Ces risques peuvent entraîner l’arrêt de l’activité professionnelle, générant ainsi une perte conséquente de revenus pouvant affecter la stabilité de l'entreprise.<br><br>Notre rôle est de réaliser un audit personnalisé :</div>
        <div class="Liste">
          <ul>
            <li><img src="@/images/icons/check.png" alt=""><span>Vérifications des besoins</span></li>
            <li><img src="@/images/icons/search.png" alt=""><span>Analyses des clauses techniques</span></li>
            <li><img src="@/images/icons/paper.png" alt=""><span>Remise d’une note de synthèse</span></li>
            <li><img src="@/images/icons/prox-hand.png" alt=""><span>Accompagnement de proximité</span></li>
          </ul>  
        </div>
      </div>
      <div class="Illustrations">
        <img src="@/images/prevoyancePro.jpg" alt="">
      </div>
    </div>   
    <div class="Part AllGarantie">
      <div class="Button"><span>Garantie</span><span>Incapacité Invalidité</span><span>et Décès</span></div>
      <div class="Button"><span>Frais Généraux</span><span>Permanents</span></div>
      <div class="Button"><span>Homme-clés /</span><span>Garanties associés</span></div>
    </div>
    <div class="Part Particuliers">
      <div class="Princ">
        <img class="Icon" src="@/images/hand-holding-heart.svg" alt="">
        <div class="Title">Particuliers</div>
        <div class="Text">La maladie ou l’accident ne préviennent pas, il est important de vous protéger ainsi que vos proches.</div>
        <div class="AllButtons">
          <div class="Button"><span>Accidents de la</span><span>vie privée</span></div>
          <div class="Button">Dépendance</div>
        </div>
        <div class="AllButtons Second">
          <div class="Button"><span>Capital décès /</span><span>Obsèques</span></div>
          <div class="Button"><span>Assurance</span><span>Emprunteur</span></div>
        </div>
      </div>
      <div class="Illustrations">
        <img src="@/images/prevoyancePart.jpg" alt="">
      </div>
    </div>    
    <div class="Liens" style="display:none">
      <router-link to="/contact"  class="Lien Contact">Contactez-nous</router-link>
      <router-link to="/actualite" class="Lien Actus">Découvrez nos actus</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "Prevoyance",
  components: {
  },
  created () {
      this.$store.commit("currentPage","prevoyance")
      this.$store.commit("toggleMenuMobile",false)
  }
};
</script>
