<template>
  <div class="Page Retraite">
    <div class="BigTitle Small">
        <div class="Trait" >
          <span class="Barre"></span><span class="Bullet"></span>
        </div>
        <div class="Text">Audit Retraite</div>
    </div>
    <div class="Part">
      <div class="Princ">
        <!-- <div class="Citations">Préparer sa retraite est un acte<br>essentiel pour assurer son futur.</div> -->
        <div class="Text">L'objectif est de maintenir vos loisirs, vos besoins ainsi que « train de vie ».<br>Prévoir sa retraite est un effort d’épargne qui doit commencer au plus tôt, quel que soit sa forme, de nombreux dispositifs permettent d'optimiser sa fiscalité tout en se créant du patrimoine. <br><br>Notre rôle est de réaliser un audit personnalisé :</div>
        <div class="Liste">
          <ul>
            <li><img src="@/images/icons/search.png" alt=""><span>Analyse de votre relevé de carrière</span></li>
            <li><img src="@/images/icons/calendrier.png" alt=""><span>Calcul de l’âge de départ à la retraite</span></li>
            <li><img src="@/images/icons/balance.png" alt=""><span>Montant précis de votre pension retraite</span></li>
          </ul>  
        </div>
      </div>
      <div class="Illustrations">
        <img src="@/images/retraite.jpg" alt="">
      </div>
    </div>
    <div class="Part Phrase"><span>En fonctions de vos besoins nous vous proposerons le contrat adapté à votre situation :</span></div>
    <div class="Part AllContrat">
      <div class="Button">PER</div>
      <div class="Button">PEE</div>
      <div class="Button">PERECO</div>
      <div class="Button">PEROB</div>
    </div>
    <div class="Liens" style="display:none">
      <router-link to="/contact"  class="Lien Contact">Contactez-nous</router-link>
      <router-link to="/actualite" class="Lien Actus">Découvrez nos actus</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "Retraite",
  components: {
  },
  created () {
      this.$store.commit("currentPage","audit-retraite")
      this.$store.commit("toggleMenuMobile",false)
  }
};
</script>
