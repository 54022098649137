import { createStore } from 'vuex'

export default createStore({
  state: {
    currentMenu : "",
    openMenuMobile:false,
    token:{
      token:null,
      date_expir:null
    }
  },
  mutations: {
    currentPage(state, page_){
      state.currentMenu = page_;
    },
    toggleMenuMobile(state, open){
      state.openMenuMobile = open;
    },
    saveToken(state, token){
      state.token = token;
    }
  },
  actions: {
		saveToken({commit}, token){
			commit("saveToken",token);
		}
  },
  modules: {},
})
