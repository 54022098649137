<template>
  <div class="Home">
    <div class="Container Presentation">
      <img class="Logo" src="@/images/logo.svg" alt="Logo Conseil Solution Professionnel">
      <div class="BigTitle">
          <div class="Trait" >
            <span class="Barre"></span>
          </div>
          <div class="Text">L'expert en Protection Sociale</div>
          <div class="Trait" >
            <span class="Barre"></span><span class="Bullet"></span>
          </div>
      </div>
      <div class="Paragraphe">CSP + est un cabinet de <span>conseil et de courtage indépendant</span> dédié à la <span>protection sociale et patrimoniale</span>.<br><br>Notre cabinet aide les cheffes et chefs d’entreprises, les professions libérales, les artisans, les commerçants et leurs familles à construire une stratégie d'optimisation sur mesure.</div>
    </div>
    <div class="Illustrations">
      <div class="Image Image1"><router-link to="/sante">
        <img class="desktop" src="@/images/photo1.png" alt="">
        <img class="mobile hor" src="@/images/photo1small.png" alt="">
        <span></span>
        <div class="Text"><p>Santé</p></div></router-link>
      </div>
      <div class="Image Image2"><router-link to="/prevoyance">
        <img class="desktop" src="@/images/photo2.png" alt="">
        <img class="mobile hor" src="@/images/photo2small.png" alt="">
        <span></span>
        <div class="Text"><p>Prévoyance</p></div></router-link>
      </div>
      <div class="Image Image3">        <router-link to="/audit-retraite">
        <img class="desktop" src="@/images/photo3.png" alt="">
        <img class="mobile" src="@/images/photo3small.png" alt="">
        <span></span>
        <div class="Text"><p>Audit Retraite</p></div></router-link>
      </div>
      <div class="Image Image4"><router-link to="/audit-patrimonial">
        <img class="desktop" src="@/images/photo4.png" alt="">
        <img class="mobile" src="@/images/photo4small.png" alt="">
        <span></span>
        <div class="Text"><p>Audit<br>Patrimonial</p></div></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  created () {
      this.$store.commit("currentPage","")
      this.$store.commit("toggleMenuMobile",false)
  }
};
</script>
