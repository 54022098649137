<template>
  <div class="Page Sante">
    <div class="BigTitle Small">
        <div class="Trait" >
          <span class="Barre"></span><span class="Bullet"></span>
        </div>
        <div class="Text">Santé</div>
    </div>
    <div class="Part Professionnels">
      <div class="Princ">
        <img class="Icon" src="@/images/briefcase.svg" alt="">
        <div class="Title">Professionnels</div>
        <div class="Text">Nous réalisons des études comparatives en fonction de vos besoins et ceux de vos salariés, tout en respectant votre budget ainsi que votre convention collective.</div>
        <div class="Populations">          
          <div class="Button"><img src="@/images/icons/individuel.png" alt=""><span>Complémentaire santé Individuel</span></div>
          <div class="Button"><img src="@/images/icons/collective.png" alt=""><span>Complémentaire santé Collective</span></div>
        </div>
      </div>
      <div class="Illustrations">
        <img src="@/images/SantePro.png" alt="">
      </div>
    </div>
    <div class="Part Particuliers">
      <div class="Princ">
        <img class="Icon" src="@/images/hand-holding-heart.svg" alt="">
        <div class="Title">Particuliers / Retraités</div>
        <div class="Text">En fonction de vos besoins (Dentaires, Optiques, Hospitalisation, Médecine douce) et de votre budget nous vous proposons une mutuelle adaptée.</div>
        <div class="Button">Complémentaire santé</div>
      </div>
      <div class="Illustrations">
        <img src="@/images/santePart.jpg" alt="">
      </div>
    </div>
    <div class="Liens" style="display:none">
      <router-link to="/contact"  class="Lien Contact">Contactez-nous</router-link>
      <router-link to="/actualite" class="Lien Actus">Découvrez nos actus</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "Sante",
  components: {
  },
  created () {
      this.$store.commit("currentPage","sante")
      this.$store.commit("toggleMenuMobile",false)
  }
};
</script>
