<template>
  <div id="HeaderMenu" class="Header" :class="{Open:$store.state.openMenuMobile}">
    <div class="Container">
      <img class="BurgerMobile" src="@/images/menu_close.svg" alt="" @click="toggleMenuMobile()" v-if="$store.state.openMenuMobile">
      <img class="BurgerMobile" src="@/images/menu.svg" alt="" @click="toggleMenuMobile()" v-else>
      <router-link to="/"><img class="Logo" src="@/images/logo.svg" alt="Logo Conseil Solution Professionnel"></router-link>
      <nav class="Menu">
        <div class="Onglet" :class="{'Selected' : $store.state.currentMenu.toLowerCase() == ''}">
          <div class="Text" @click="closeMenuMobile()">
            <router-link to="/">Accueil</router-link>
          </div>
          <div class="Trait">
            <span class="Barre"></span><span class="Bullet"></span>
          </div>
        </div>
        <div class="Onglet" v-for="(onglet_,i) in lstOnglets" :key="'Onglet#'+i" :class="{'Selected' : $store.state.currentMenu.toLowerCase() == onglet_.href.replace('/','').toLowerCase()}"> 
          <div class="Text" @click="closeMenuMobile()"><router-link :to="onglet_.href">{{onglet_.text}}</router-link></div>
          <div class="Trait">
            <span class="Barre"></span><span class="Bullet"></span>
          </div>
        </div>
      </nav>  
      <a class="Tel" href="tel:+33630335260">
        <img src="@/images/call.svg" alt="">
        <div>06 30 33 52 60</div>
      </a>
    </div>
    <div class="">

    </div>
  </div>  
</template>

<script>
export default {
  name: "Header",
  props: {
  },
  data(){
    return{
      openMenuMobile:false,
      lstOnglets:[
        {
          text:"Santé",
          href:"/sante"
        },
        {
          text:"Prévoyance",
          href:"/prevoyance"
        },
        {
          text:"Audit Retraite",
          href:'/audit-retraite'
        },
        {
          text:"Audit Patrimonial",
          href:'/audit-patrimonial'
        }
      ]
    }
  },
  created(){
    window.addEventListener('scroll', this.scrollFunction);
  },
  methods:{
    async toggleMenuMobile(){
      this.openMenuMobile = !this.openMenuMobile;
      this.$store.commit("toggleMenuMobile",this.openMenuMobile)
    },
    async closeMenuMobile(){
      this.openMenuMobile = false;
      this.$store.commit("toggleMenuMobile",this.openMenuMobile)
    },
    scrollFunction() {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        document.getElementById("HeaderMenu").classList.add("Sticky");
      } else {
        document.getElementById("HeaderMenu").classList.remove("Sticky");
      }
    }
  }
};
</script>