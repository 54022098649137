import axios from 'axios';

export default {
    async callURLApi(url, datas) {
        const token = "CSPPB3FF3C1291854FCD7BF4DB4955D19BB55071D4978F94AB3E3F998FF7ACAE9";

        // Créer un nouvel objet FormData
        const formData = new FormData();

        // Ajouter le token aux données du formulaire
        formData.append('token', token);

        // Ajouter les autres données au formulaire
        Object.keys(datas).forEach(key => {
            formData.append(key, datas[key]);
        });

        // Envoyer la requête POST
        return await axios.post(`https://api.lavitrineweb.fr/v1${url}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.error(error);
        });
        
    },

    async sendMail(datas) {
        return await this.callURLApi("/mail/send/", datas)
    }

}