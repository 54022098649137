<template>
  <div id="app">
    <Header/>
    <router-view />
    
    <Contact :class="$route.name" v-if="$route.name != 'contact'"/>
    <Footer/>
  </div>
</template>

<script>

  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import Contact from "@/components/Contact.vue";

  export default {
    name: "App",
    components: {
      Header,
      Footer,
      Contact
    },
  metaInfo: {
    title: 'CSP + - Conseil Solution Professionnel', 
    meta: [
      { property: 'og:title', content: "CSP + - Conseil Solution Professionnel"},
      { property: 'og:description', content: 'CSP+ Cabinet de conseil et de courtage indépendant dédié à la protection sociale et patrimoniale.'},
      { property: 'og:site_name', content: 'CSP + - Conseil Solution Professionnel'},
      { property: 'og:type', content: 'website'},    
      { name: 'robots', content: 'index,follow'} ,      
      { property: 'og:url', content: "http://csp-plus.com"}
    ]
  },
  };
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import "./css/style.scss";
</style>
